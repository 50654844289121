<template>
  <b-modal ref="programModal" hide-footer title="Select Program" centered lazy scrollable @hide="hideModal" size="lg">
    <page-separator>
      <b-link v-b-toggle="'section-1'" class="accordion__toggle p-0">
        <span class="flex">Programs</span>
        <md-icon class="accordion__toggle-icon">
          {{ stepOneVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
        </md-icon>
      </b-link>
    </page-separator>

    <b-collapse
      id="section-1"
      accordion="section-1"
      :visible="stepOneVisible"
      style="transition: 0.5s"
      class="accordion__menu"
      @hide="stepOneVisible = false"
      @show="stepOneVisible = true"
    >
      <div class="row px-2 ma-0" v-if="areProgramsLoading">
        <div class="col-md-4 col-sm-4 mb-2" v-for="i in [1, 2, 3, 4, 5, 6]" :key="i">
          <div class="card" style="height: 90%; border-radius: 0">
            <b-skeleton-img></b-skeleton-img>
            <b-skeleton class="ml-2 my-2" width="40%"></b-skeleton>
          </div>
        </div>
      </div>
      <div class="row px-2 ma-0">
        <div class="col-md-4 col-sm-4 mb-2" v-for="program in programs" :key="get(program, 'id')">
          <div
            class="card"
            v-if="selectedProgram"
            style="cursor: pointer; height: 90%"
            @click.prevent="changeProg(program)"
          >
            <div style="position: relative">
              <img
                :src="program.image_url"
                :alt="program.title"
                style="object-fit: cover; border-radius: 0.5rem 0.5rem 0rem 0rem"
                width="100%"
                height="100px"
                class="mb-2"
              />

              <b-badge
                v-if="program.id === selectedProgram.id"
                pill
                variant="light"
                style="position: absolute; top: 5px; right: 5px"
              >
                <md-icon class="text-success">check</md-icon>
                Selected
              </b-badge>
              <div class="p-2">
                {{ program.title }}
              </div>
            </div>
          </div>
          <div
            class="card"
            v-else-if="program.id"
            style="cursor: pointer; height: 90%"
            @click.prevent="changeProg(program)"
          >
            <div style="position: relative">
              <img
                :src="program.image_url"
                :alt="program.title"
                style="object-fit: cover; border-radius: 0.5rem 0.5rem 0rem 0rem"
                width="100%"
                height="100px"
                class="mb-2"
              />

              <b-badge
                v-if="get(student, 'enrolled_program.id') === program.id"
                pill
                variant="light"
                style="position: absolute; top: 5px; right: 5px"
              >
                <md-icon class="text-success">check</md-icon>
                Selected
              </b-badge>
              <div class="p-2">
                {{ program.title }}
              </div>
            </div>
          </div>

          <div class="card" v-else style="cursor: pointer; height: 90%">
            <div style="position: relative">
              <div
                style="width: 100%; height: 100px; border-radius: 0.5rem 0.5rem 0rem 0rem"
                class="bg-primary mb-2"
              ></div>
              <b-badge pill variant="light" style="position: absolute; top: 5px; right: 5px">
                <md-icon class="text-success">check</md-icon>
                Selected
              </b-badge>
              <div class="p-2">
                {{ program.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>

    <page-separator>
      <b-link v-b-toggle="'section-2'" class="accordion__toggle p-0">
        <span class="flex">Program Tags</span>
        <md-icon class="accordion__toggle-icon">
          {{ stepTwoVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
        </md-icon>
      </b-link>
    </page-separator>

    <b-collapse
      id="section-2"
      accordion="section-2"
      :visible="stepTwoVisible"
      style="transition: 0.5s"
      class="accordion__menu"
      @hide="stepTwoVisible = false"
      @show="stepTwoVisible = true"
    >
      <div class="row px-3 ma-0">
        <v-select
          id="tag"
          class="form-control v-select-custom"
          label="title"
          v-model="selectedTag"
          :value="selectedTag"
          :reduce="sc => sc.id"
          placeholder="Select Program Tag"
          :options="allTags"
          :loading="areTagsLoading && !allTags.length"
          @search="fetchOptions"
          @input="changeTag"
          :disabled="$route.name === 'edit-attendance' || (areTagsLoading && !allTags.length) || !selectedProgram"
        >
          <template #list-footer>
            <li
              v-observe-visibility="visibilityChanged"
              v-show="allTags.length && allTags.length < totalTags"
              class="loader"
            ></li>
          </template>
          <template slot="option" slot-scope="option">
            <span :id="`tag-${option.id}`">
              {{ option.title }}
            </span>
          </template>
          <template slot="selected-option" slot-scope="option">
            {{ option.title }}
          </template>
        </v-select>
      </div>
    </b-collapse>
    <div class="d-flex justify-content-end mt-3">
      <b-btn variant="primary" style="width: 150px" @click.prevent="update" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>Update</span>
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
import { get, debounce } from 'lodash';
import { mapActions } from 'vuex';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import Vue from 'vue';
import { ObserveVisibility } from 'vue-observe-visibility';

Vue.directive('observe-visibility', ObserveVisibility);

export default {
  components: { PageSeparator },
  name: 'ProgramModal',

  props: {
    showModal: { type: Boolean, default: false },
    student: { type: Object, default: () => {} }
  },

  data() {
    return {
      programs: [],
      isProgramChanged: false,
      areProgramsLoading: false,
      selectedProgram: null,
      selectedTag: null,
      stepOneVisible: false,
      stepTwoVisible: false,
      allTags: [],
      areTagsLoading: false,
      isLoading: false,
      limit: 15,
      offset: 0,
      totalTags: 0,
      search: ''
    };
  },

  computed: {},

  watch: {
    showModal(value) {
      if (value) {
        if (this.student.enrolled_program) {
          this.selectedProgram = this.student.enrolled_program;
          this.fetchTags();
        }

        this.selectedTag = this.student.selected_tag;
        this.fetchPrograms();

        this.$refs.programModal.show();
      }
    },

    selectedProgram: {
      handler() {
        this.allTags = [];
        this.offset = 0;
        this.tag_id = null;
        this.search = '';
      }
    }
  },

  methods: {
    ...mapActions('program', ['getAllPrograms']),
    ...mapActions('student', ['updateStudentProgram', 'updateStudentTag']),
    ...mapActions('attendance', ['getTags']),
    get,
    changeProg(program) {
      this.selectedProgram = program;
      this.selectedTag = null;
      this.fetchTags();
      if (this.allTags.length) {
        this.stepOneVisible = false;
      }
      this.stepTwoVisible = true;
    },
    changeTag(tag) {
      this.selectedTag = tag;
    },
    async update() {
      //const h = this.$createElement;

      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm('Are you sure you want to continue?', {
          title: 'Are you sure?',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        });
        if (isConfirmed) {
          const prgData = {
            enrolled_program: this.selectedProgram.id
          };
          const tagData = {
            selected_tag: this.selectedTag
          };

          if (this.selectedProgram.id !== get(this.student, 'enrolled_program.id')) {
            await this.updateStudentProgram({ id: this.student.id, data: prgData });
          }
          if (this.selectedTag !== get(this.student, 'selected_tag')) {
            await this.updateStudentTag({ id: this.student.id, data: tagData });
          }
          this.makeToast({
            variant: 'success',
            msg: 'Updated Successfully!'
          });
          this.isProgramChanged = true;
          this.hideModalManual();
        } else {
          this.isProgramChanged = false;
        }
      } catch (error) {
        //
      }
    },
    visibilityChanged(reached) {
      if (reached) {
        this.offset += 15;
        this.fetchTags();
      }
    },
    async fetchTags() {
      this.tag_id = null;
      if (!this.search) this.areTagsLoading = true;
      const response = await this.getTags({
        ...(this.search && { search: this.search }),
        program__id: this.selectedProgram.id,
        limit: this.limit,
        offset: this.offset
      });

      if (!this.allTags.length) {
        this.stepOneVisible = true;
      }

      this.allTags = this.allTags.concat(response.data.data.results);
      this.totalTags = response.data.data.count;
      this.areTagsLoading = false;

      if (this.allTags.length > 15) {
        setTimeout(() => {
          const el = document.getElementById(`tag-${this.allTags.slice(-15)[0].id}`);

          if (el) {
            el.scrollIntoView({ behavior: 'instant', block: 'nearest' });
          }
        }, 100);
      }
    },

    debouncedSearchTag: debounce(async (loading, search, vm) => {
      vm.search = search;
      const response = await vm.getTags({
        search,
        program__id: vm.selectedProgram.id,
        limit: vm.limit,
        offset: vm.offset
      });
      loading(false);

      vm.allTags = [];
      vm.totalTags = 0;
      vm.allTags = vm.allTags.concat(response.data.data.results);
      vm.totalTags = response.data.data.count;
    }, 500),

    async fetchOptions(search, loading) {
      if (!this.tag_id) {
        this.offset = 0;
        this.allTags = [];
        this.search = search;
        loading(true);
        this.debouncedSearchTag(loading, search, this);
      }
    },

    async fetchPrograms() {
      this.areProgramsLoading = true;
      try {
        const res = await this.getAllPrograms({ ordering: 'id' });
        if (res.data) {
          const progs = res.data.map(program => program.id);
          if (this.student.enrolled_program) {
            if (!progs.includes(this.student.enrolled_program.id)) {
              this.programs = res.data;
              this.programs.push(this.student.enrolled_program);
            } else {
              this.programs = res.data;
            }
          } else {
            this.programs = res.data;
            this.programs.push({ id: null, title: 'Other Program' });
          }
        }
      } catch (err) {
        // Handle error if needed
      }
      this.areProgramsLoading = false;
    },

    hideModal() {
      this.selectedProgram = null;
      this.selectedTag = null;
      this.stepOneVisible = false;
      this.stepTwoVisible = false;
      this.$emit('close', this.isProgramChanged);
    },

    hideModalManual() {
      this.$refs.programModal.hide();
      this.hideModal();
    }
  }
};
</script>
