<template>
  <div>
    <div class="d-flex align-items-center justify-content-center flex-wrap">
      <div class="d-flex align-items-center uni-search">
        <form class="uni-search search-form search-form--light d-lg-inline-flex " @submit.prevent>
          <b-form-input class="w-lg-auto" placeholder="Search universities" v-model="searchTerm" @input="onSearch" />
          <b-btn variant="flush" type="submit">
            <md-icon>search</md-icon>
          </b-btn>
        </form>
      </div>
          <b-btn 
            v-if="enableSidebarToggle !== false" 
            v-fmv-toggle.filters 
            size="sm" 
            variant="white" 
            class="ml-auto mb-2"
          >
            <md-icon left>tune</md-icon>
            <span>Filters</span>
          </b-btn>

      <div class="ml-auto list-filter-btns mb-0">
        <b-form-radio-group
          id="list-filter-radio"
          name="list-filter-radio"
          class="w-100"
          v-model="selectedList"
          buttons
          button-variant="outline-secondary"
        >
          <b-form-radio
            v-for="(option, index) in listFilterOptions"
            :key="index"
            :value="option.value"
            class="w-100 text-center list-filter-btn-opt"
          >
            {{ option.text }}
          </b-form-radio>
        </b-form-radio-group>
      </div>
    </div>

    <page-separator
      :title="
        !isLoading
          ? `showing ${totalUniversities ? `${pageFromCount}-${pageToCount}` : 0} of ${totalUniversities} Universities`
          : ''
      "
    />

    <div class="d-flex justify-content-center" v-if="!totalUniversities && !isLoading">
      <div class="card card-block card-stretch card-height col-md-8">
        <div class="card-body text-center">
          <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
          <h4>No Universities Found</h4>
          <p class="text-muted">
            No universities currently available. Please check again later.
          </p>
        </div>
      </div>
    </div>

    <b-skeleton-wrapper :loading="isLoading" v-else>
      <template #loading>
        <div class="row">
          <div class="col-md-3" v-for="item in [1, 2]" :key="item">
            <div class="card">
              <b-skeleton-img width="100%"></b-skeleton-img>

              <div class="p-2">
                <b-skeleton width="55%"></b-skeleton>
                <b-skeleton width="70%"></b-skeleton>
              </div>

              <div class="p-2">
                <b-skeleton type="button" width="100%"></b-skeleton>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="row">
        <div class="col-sm-6 col-lg-4 col-xl-3" v-for="(university, index) in universities" :key="university.id">
          <div
            class="card clickable-item"
            style="height: 95%;"
            @click.prevent="() => $router.push({ name: 'std-cc-uni-checklist', params: { slug: university.slug } })"
          >
            <img
              style="object-fit: cover; border-radius: 8px"
              :src="university.campus_img_url ? university.campus_img_url : DefaultAvatar"
              width="100%"
              height="150px"
            />

            <div class="p-2 h-100 d-flex flex-column">
              <div class="d-flex align-items-center justify-content-between my-auto">
                <div class="d-flex align-items-center">
                  <img
                    style="border-radius: 50px"
                    :src="university.small_logo_url ? university.small_logo_url : DefaultAvatar"
                    width="30px"
                    height="30px"
                    class="mr-2"
                  />
                  <div class="card-title truncate-title">{{ university.name }}</div>
                </div>
                <div @click.stop.prevent="toggleFav(index)">
                  <md-icon style="font-size: 25px;" class="text-primary ml-2">{{
                    university.fav_status ? 'favorite' : 'favorite_border'
                  }}</md-icon>
                </div>
              </div>

              <div @click.stop>
                <router-link
                  :to="{ name: 'std-cc-uni-checklist', params: { slug: university.slug } }"
                  class="btn btn-primary w-100 mt-2"
                >
                  Explore <md-icon class="ml-2">open_in_new</md-icon></router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-skeleton-wrapper>

    <pagination
      v-if="totalUniversities > universities.length"
      v-model="currentPage"
      :total-rows="totalUniversities"
      :per-page="perPage"
      @change="onPageChange"
      aria-controls="universities-table"
    />
  </div>
</template>

<script>
import { debounce, get } from 'lodash';
import Pagination from '@/components/Ui/Pagination.vue';
import { mapActions, mapGetters } from 'vuex';
import DefaultAvatar from '@/assets/images/logos/logo_icon.png';
import { USER_ROLES } from '@/common/constants';
import Page from '@/components/Page.vue';
import { formatFullDate } from '@/common/utils';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import Filters from './Filters/Filters.vue';
import FiltersSidebar from './Filters/FiltersSidebar.vue';

export default {
  name: 'DashUniversitiesList',
  components: { Pagination, PageSeparator },
  extends: Page,

  props: {},

  data() {
    return {
      DefaultAvatar,
      title: 'Dashboard',
      isLoading: true,
      perPage: 24,
      currentPage: 1,
      totalUniversities: 0,
      universities: [],
      searchTerm: '',
      ordering: '-id',
      pageFromCount: 0,
      pageToCount: 0,
      windowWidth: window.innerWidth,
      USER_ROLES,

      listFilterOptions: [
        { text: 'All', value: 'all' },
        { text: 'Favorites', value: 'favorites' },
      ],
      selectedList: '',
      onfirstLoad: true,
      careCampusUrl: process.env.VUE_APP_UNI_SPONSORED_URL,
      filters: {
        offering: null,
        tuition_from: null,
        tuition_to: null,
        program_id: null,
        state: null,
        tuition: null,
      },
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('uni', ['getUniFilters']),

    isMobSmallScreen() {
      return this.windowWidth <= 776;
    },

    subLayout() {
      return this.windowWidth > 992;
    },
    subLayoutDrawer() {
      return this.windowWidth > 992 ? FiltersSidebar : Filters;
    },
    subLayoutDrawerId() {
      return 'filters';
    },

    enableSidebarToggle() {
      return this.windowWidth <= 992;
    },
  },

  watch: {
    selectedList: {
      handler() {
        this.searchTerm = '';
        this.fetchUniversities();
        if (this.$route.query.list !== this.selectedList) {
          this.$router.replace({ query: { list: this.selectedList } });
        }
      },
    },
    getUniFilters: {
      deep: true,
      handler(filterValues) {    
        this.filters.program_id = filterValues.program_id;
        this.filters.offering = filterValues.offering?.length ? (filterValues.offering || []).join(',') : null;
        this.filters.tuition_from = filterValues.tuition[0];
        this.filters.tuition_to = filterValues.tuition[1];
        this.filters.state = filterValues.state;
        if(!this.onfirstLoad) this.debouncedSearchUniversities(this);
        setTimeout(() => {
          this.onfirstLoad = false;
        }, 500);    
      },
    },
  },

  methods: {
    ...mapActions('uni', ['getAllUniversities', 'updateFavStatus', 'getFavUniversities']),
    get,
    formatFullDate,

    async toggleFav(uniIndex) {
      try {
        await this.updateFavStatus({
          uni_id: this.universities[uniIndex].id,
          fav_status: !this.universities[uniIndex].fav_status,
        });
        this.universities[uniIndex].fav_status = !this.universities[uniIndex].fav_status;
        if (this.selectedList === 'favorites') {
          this.fetchUniversities(
            this.currentPage > 1 && this.universities.length === 1 ? this.currentPage - 1 : this.currentPage,
          );
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },

    async fetchUniversities(pageNum = 1, params = {}) {
      if (this.selectedList === 'favorites') {
        this.fetchFavUniversities(pageNum, params);
      } else {
        this.fetchAllUniversities(pageNum, params);
      }
    },

    async fetchAllUniversities(pageNum = 1, params = {}) {
      this.isLoading = true;

      try {
        const response = await this.getAllUniversities({
          limit: this.perPage,
          offset: (pageNum - 1) * this.perPage,
          ...(this.searchTerm && { search: this.searchTerm }),
          ...this.filters,
          ...params,
        });

        this.universities = response.data.results;
        this.currentPage = pageNum;
        this.totalUniversities = response.data.count;
        this.pageFromCount = (this.currentPage - 1) * this.perPage + 1;
        this.pageToCount = this.pageFromCount + get(response.data, 'results.length', 0) - 1;
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.isLoading = false;
    },

    async fetchFavUniversities(pageNum = 1, params = {}) {
      this.isLoading = true;

      try {
        const response = await this.getFavUniversities({
          limit: this.perPage,
          offset: (pageNum - 1) * this.perPage,
          ...(this.searchTerm && { search: this.searchTerm }),
          ...this.filters,
          ...params,
        });

        this.universities = response.data.results.map(uni => ({ ...uni, fav_status: true }));
        this.currentPage = pageNum;
        this.totalUniversities = response.data.count;
        this.pageFromCount = (this.currentPage - 1) * this.perPage + 1;
        this.pageToCount = this.pageFromCount + get(response.data, 'results.length', 0) - 1;
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchUniversities(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchUniversities();
    },

    onSearch() {
      this.debouncedSearchUniversities(this);
    },

    debouncedSearchUniversities: debounce(vm => {
      vm.fetchUniversities();
    }, 500),

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);
    this.selectedList = this.$route.query.list || 'all';
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
.truncate-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.list-filter-btn-opt {
  width: 50%;
}
.list-filter-btns {
  @media (min-width: 577px) {
    max-width: 250px;
  }
  width: 100%;
}
.uni-search {
  @media (max-width: 576px) {
    width: 100%;
    margin-bottom: 0.5rem !important;
  }
}
</style>
