var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('div',{staticClass:"d-flex"},[_c('div',{staticStyle:{"width":"6%"}},[_c('b-skeleton-icon',{attrs:{"icon":"question","icon-props":{ fontScale: 3 }}})],1),_c('div',{staticClass:"mr-2",staticStyle:{"width":"82%"}},[_c('b-skeleton',{attrs:{"width":"25%"}}),_c('b-skeleton',{attrs:{"width":"95%"}})],1),_c('div',[_c('b-skeleton',{attrs:{"type":"button","width":"100px"}})],1)]):_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex align-items-center mr-2"},[(_vm.icon)?_c('md-icon',{staticClass:"mr-2 setup-guide-icon"},[_vm._v(_vm._s(_vm.icon))]):_c('i',{staticClass:"fas fa-coins ml-1 setup-guide-icon-font-awesome",staticStyle:{"margin-right":"10px"}}),_c('div',[_c('strong',[_vm._v(_vm._s(_vm.title))]),_c('p',{staticClass:"mb-0 text-muted"},[_vm._v(" "+_vm._s(_vm.description)+" ")]),(_vm.abbr === 'add_new_offers')?_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" No new offers yet? "),_c('a',{staticClass:"ml-1 mt-2 text-secondary",attrs:{"href":((_vm.getPageLink(_vm.get(_vm.getLoggedInUser, 'linked_entity.organization_site.wbst_domain'))) + "jobs"),"target":"_blank"}},[_vm._v("Explore More")])]):_vm._e(),(_vm.abbr === 'add_certifications')?_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" No certifications? "),_c('a',{staticClass:"ml-1 mt-2 text-secondary",staticStyle:{"font-size":"12px"},attrs:{"href":((_vm.getPageLink(_vm.get(_vm.getLoggedInUser, 'linked_entity.organization_site.wbst_domain'))) + "programs"),"target":"_blank"}},[_vm._v("Learn More")])]):_vm._e()])],1),(_vm.isLoading)?_c('b-btn',{staticClass:"setup-guide-btn btn-normal",attrs:{"variant":"primary","disabled":""}},[_c('i',{staticClass:"fas fa-circle-notch fa-spin"})]):(_vm.status)?_c('b-btn',{staticClass:"setup-guide-btn btn-normal",attrs:{"variant":"success","href":_vm.abbr === 'career_assessment'
          ? ((_vm.getPageLink(
              _vm.get(_vm.getLoggedInUser, 'linked_entity.organization_site.wbst_domain')
            )) + "career-assessment?uuid=" + (_vm.get(_vm.getLoggedInUser, 'user_uuid')))
          : null,"to":{ name: _vm.redirectTo },"target":_vm.abbr === 'career_assessment' ? '_blank' : ''}},[_c('md-icon',[_vm._v("check")])],1):_c('router-link',{staticClass:"btn btn-primary setup-guide-btn btn-normal",attrs:{"href":_vm.abbr === 'career_assessment'
          ? ((_vm.getPageLink(
              _vm.get(_vm.getLoggedInUser, 'linked_entity.organization_site.wbst_domain')
            )) + "career-assessment?uuid=" + (_vm.get(_vm.getLoggedInUser, 'user_uuid')))
          : null,"to":{ name: _vm.redirectTo },"target":_vm.abbr === 'career_assessment' ? '_blank' : ''}},[_vm._v(_vm._s(_vm.btnText))])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }