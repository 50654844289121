<template>
  <div>
    <div class="d-flex" v-if="isLoading">
      <div style="width: 6%">
        <b-skeleton-icon icon="question" :icon-props="{ fontScale: 3 }"></b-skeleton-icon>
      </div>
      <div class="mr-2" style="width: 82%">
        <b-skeleton width="25%"></b-skeleton>
        <b-skeleton width="95%"></b-skeleton>
      </div>
      <div>
        <b-skeleton type="button" width="100px"></b-skeleton>
      </div>
    </div>
    <div v-else class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center mr-2">
        <md-icon v-if="icon" class="mr-2 setup-guide-icon">{{ icon }}</md-icon>
        <i v-else style="margin-right: 10px" class="fas fa-coins ml-1 setup-guide-icon-font-awesome"></i>
        <div>
          <strong>{{ title }}</strong>
          <p class="mb-0 text-muted">
            {{ description }}
          </p>
          <span style="font-size: 12px;" v-if="abbr === 'add_certifications'">
            No certifications?
            <a
              class="ml-1 mt-2 text-secondary"
              style="font-size: 12px;"
              :href="`${careCampusUrl}/${get(selectedUni, 'slug')}/programs`"
              target="_blank"
              >Learn More</a
            >
          </span>
        </div>
      </div>
      <b-btn variant="primary" class="setup-guide-btn btn-normal" disabled v-if="isLoading"
        ><i class="fas fa-circle-notch fa-spin"></i
      ></b-btn>
      <b-btn
        variant="success"
        class="setup-guide-btn btn-normal"
        :href="
          abbr === 'career_assessment'
            ? `${careCampusUrl}/${get(selectedUni, 'slug')}/career-assessment?uuid=${get(getLoggedInUser, 'user_uuid')}`
            : null
        "
        :to="{
          name: redirectTo,
          ...(abbr === 'practice_skill_simulations' && { params: { uniId: get(selectedUni, 'id') } }),
        }"
        :target="abbr === 'career_assessment' ? '_blank' : ''"
        v-else-if="status"
        ><md-icon>check</md-icon></b-btn
      >
      <router-link
        v-else
        class="btn btn-primary setup-guide-btn btn-normal"
        :href="
          abbr === 'career_assessment'
            ? `${careCampusUrl}/${get(selectedUni, 'slug')}/career-assessment?uuid=${get(getLoggedInUser, 'user_uuid')}`
            : null
        "
        :to="{
          name: redirectTo,
          ...(abbr === 'practice_skill_simulations' && { query: { uniId: get(selectedUni, 'id') } }),
        }"
        :target="abbr === 'career_assessment' ? '_blank' : ''"
        >{{ btnText }}</router-link
      >
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import { mapGetters } from 'vuex';
import { getClickableLink } from '@/common/utils';

export default {
  name: 'CareCampusChecklistRow',
  props: {
    icon: { type: String, default: () => '' },
    title: { type: String, default: () => '' },
    description: { type: String, default: () => '' },
    redirectTo: { type: String, default: () => '' },
    isLoading: { type: Boolean, default: () => true },
    status: { type: Boolean, default: () => false },
    btnText: { type: String, default: () => 'Explore' },
    abbr: { type: String, default: () => '' },
    selectedUni: { type: Object, default: () => {} },
  },

  data() {
    return {
      careCampusUrl: process.env.VUE_APP_UNI_SPONSORED_URL,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
  },

  methods: {
    get,

    getPageLink(url) {
      const cUrl = getClickableLink(url);
      return cUrl.endsWith('/') ? cUrl : `${cUrl}/`;
    },
  },
};
</script>

<style></style>
