<template>
  <div class="page-section">
    <div :class="containerClass">
      <div class="d-flex justify-content-between align-items-center mb-3 flex-wrap">
        <div class="d-flex align-items-center">
            <span class="material-icons icon-32pt clickable-item" @click.prevent="() => $router.go(-1)"
              >navigate_before</span
            >
            <b-skeleton width="250px" height="20px" class="ml-2" v-if="isLoading"></b-skeleton>
            <div v-else class="d-flex align-items-center">
              <img
                style="border-radius: 50px"
                :src="selectedUni.small_logo_url ? selectedUni.small_logo_url : DefaultAvatar"
                width="30px"
                height="30px"
              />
              <h3 class="mb-0 ml-2">{{ selectedUni.name || 'University' }} Checklist</h3>
            </div>
          </div>
        </div>

      <p>
        Follow the tasks listed below to earn rewards and improve your profile.
        <a
          :href="`${careCampusUrl}/${selectedUni.slug}`"
          class=""
          :style="`color: ${selectedUni.primary_color}`"
          target="_blank"
        >
          Explore more about {{ selectedUni.name || 'the university' }}.</a
        >
      </p>

      <div class="card">
        <div class="card-body">
          <div v-for="(guide, index) in carecampusDashboard" :key="guide.id">
            <care-campus-checklist-row
              :title="guide.title"
              :description="guide.description"
              :isLoading="isScoreLoading"
              :status="!!get(orgStatuses, `${guide.abbr}.earned`)"
              :redirect-to="guide.redirectTo"
              :icon="guide.icon"
              :abbr="guide.abbr"
              :btn-text="guide.btnText"
              :selectedUni="selectedUni"
            />
            <hr v-if="index + 1 < carecampusDashboard.length" />
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body" style="padding: 1.2rem;">
          <div class="title">
            Connect with Admissions
          </div>
          <div class="d-flex justify-content-between">
            <div class="mr-2 text-muted">
              Interesed in learning more about healthcare programs, scholarships, and admissions at
              {{ selectedUni.name }}? Request personalized information directly from the admissions team.
            </div>
            <div></div>
            <div class="d-flex align-items-center">
              <b-btn variant="success" style=" pointer-events: none;" class="connect-btn" v-if="studentContactStatus.is_contacted">
                <md-icon  class="mr-1">check</md-icon>Request Sent</b-btn>
              <b-btn
                class="connect-btn"
                v-else
                @click.prevent="contactAction"
                :style="
                  selectedUni.primary_color
                    ? `background: ${selectedUni.primary_color};border-color: ${selectedUni.primary_color}`
                    : 'background:  var(--primary-color)'
                "
                >Connect me</b-btn
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, isEmpty, truncate } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import Page from '../../../components/Page.vue';
import CareCampusChecklistRow from './CareCampusChecklistRow.vue';
import { DASHBOARD_TYPES } from '@/common/constants';

export default {
  extends: Page,
  name: 'SchoolSetupGuide',
  components: { CareCampusChecklistRow },
  data() {
    return {
      title: 'Dashboard',
      completeHeadTitle: 'Dashboard - Transition',
      studentContactStatus: {
        is_contacted: false,
      },
      isLoading: true,
      isScoreLoading: true,

      orgStatuses: {},
      windowWidth: window.innerWidth,
      DASHBOARD_TYPES,

      selectedUni: {},
      careCampusUrl: process.env.VUE_APP_UNI_SPONSORED_URL,

      carecampusDashboard: [
        {
          id: 1,
          title: 'Take a Career Assessment',
          abbr: 'career_assessment',
          btnText: 'Complete',
          description: 'Take career assessment on organization site to collect points',
          enable: true,
          icon: 'assessment',
          redirectTo: '',
        },
        {
          id: 2,
          title: 'Add Education to Resume',
          abbr: 'education_resume',
          btnText: 'Complete',
          description: 'Include your educational background on your resume and earn rewards for each entry.',
          enable: true,
          icon: 'description',
          redirectTo: 'std-manage-resume',
        },
        {
          id: 3,
          title: 'Add Work Experience to Resume',
          abbr: 'work_experience_resume',
          btnText: 'Complete',
          description: 'Include work experience on your resume and earn rewards for each entry.',
          enable: true,
          icon: 'description',
          redirectTo: 'std-manage-resume',
        },
        {
          id: 4,
          title: 'Add Skills to Resume',
          abbr: 'skills_resume',
          btnText: 'Complete',
          description: 'Include skills to your resume and earn rewards for each entry.',
          enable: true,
          icon: 'description',
          redirectTo: 'std-manage-resume',
        },
        {
          id: 5,
          title: 'Add Latest Title and Location to Resume',
          abbr: 'latest_title_location_resume',
          btnText: 'Complete',
          description: 'Complete your resume by adding the title and location.',
          enable: true,
          icon: 'description',
          redirectTo: 'std-manage-resume',
        },
        {
          id: 6,
          title: 'Practice Skill Simulations',
          abbr: 'practice_skill_simulations',
          btnText: 'Complete',
          description: 'Practice with role-based interviews to showcase your skills to potential recruiters.',
          enable: true,
          icon: 'mic',
          redirectTo: 'student-simulations-listing',
        },
        {
          id: 7,
          title: 'Add Certifications',
          abbr: 'add_certifications',
          btnText: 'Complete',
          description: 'Showcase your certifications to demonstrate your skills and expertise.',
          enable: true,
          icon: 'assignment_turned_in',
          redirectTo: 'add-certificate',
        },
        {
          id: 8,
          title: 'Add Courses',
          abbr: 'add_new_courses',
          btnText: 'Complete',
          description: 'Highlight the courses you enrolled to showcase your career growth.',
          enable: true,
          icon: 'local_library',
          redirectTo: 'add-course',
        },
      ],
    };
  },
  watch: {
    selectedUni: {
      handler(value) {
        if (value) {
          this.fetchStudentUniContactStatus();
        }
      },
    },
  },

  computed: {
    ...mapGetters('navbarConfig', ['getLinkedEntity']),
    ...mapGetters('auth', ['getLoggedInUser', 'getLoggedInUserDashboard']),

    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Setup Guide', active: true },
      ];
    },
    isMobileScreen() {
      return this.windowWidth <= 600;
    },
  },

  methods: {
    ...mapActions('student', [
      'getStudentDashboardStatus',
      'createStudentUniversityConnection',
      'getStudentUniConnectionStatus',
      'getDashboardScore',
    ]),
    ...mapActions('uni', ['getUniFromSlug']),
    get,
    isEmpty,

    loadVoiceflow() {
      var stdBotApiKey = this.getLoggedInUser.linked_entity.std_bot_api_key;
      var id = this.getLoggedInUser.id.toString();
      var v = document.createElement('script'),
        s = document.getElementsByTagName('script')[0];

      v.onload = function() {
        window.voiceflow.chat.load({
          verify: { projectID: stdBotApiKey },
          url: 'https://general-runtime.voiceflow.com',
          versionID: 'production',
          userID: id,
        });
      };

      v.src = 'https://cdn.voiceflow.com/widget/bundle.mjs';
      v.type = 'text/javascript';
      s.parentNode.insertBefore(v, s);
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    async fetchStudentUniContactStatus() {
      const payload = {
        university: this.selectedUni.id,
      };
      const resp = await this.getStudentUniConnectionStatus(payload);
      this.studentContactStatus = resp.data;
    },
    async contactAction() {
      const payload = {
        from_entity_id: this.selectedUni.id,
      };
      await this.createStudentUniversityConnection(payload);
      this.fetchStudentUniContactStatus();
    },
    async fetchDashboardScore() {
      this.isScoreLoading = true;
      try {
        this.orgStatuses = (
          await this.getDashboardScore({
            id: this.selectedUni.id,
            entity_type: 'university',
          })
        ).data;
      } catch (_err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isScoreLoading = false;
    },

    async fetchUniversity() {
      this.isLoading = true;
      const uniSlug = this.$route.params.slug;
      if (uniSlug) {
        const response = await this.getUniFromSlug(uniSlug);
        this.selectedUni = response.data;
        this.fetchDashboardScore();
        this.completeHeadTitle = `${truncate(this.selectedUni.name)} Checklist - Transition`;
      }
      this.isLoading = false;
    },
  },

  async mounted() {
    this.fetchUniversity();
    window.addEventListener('resize', this.handleResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
.connect-btn {
  @media (min-width: 999px) {
    width: 200px;
    min-width: 200px;
  }
  @media (max-width: 998px) and (min-width: 380px) {
    width: 150px;
    min-width: 150px;
  }
}
.title {
  color: #272c33;
  font-size: 18px;
  font-weight: bold;
}
.contact-me {
  font-size: 16px;
  font-weight: 600;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.call-icon {
  font-size: 18px;
  color: white !important;
  background: var(--primary-color);
  border-radius: 20px;
  padding: 5px;
}
</style>
