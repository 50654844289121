<template>
  <div data-perfect-scrollbar>
    <b-skeleton-wrapper :loading="isLoading" v-if="isLoading">
      <template #loading>
        <div class="page-section pb10 student-page">
          <div
            class="container page__container d-flex flex-column flex-md-row align-items-center text-center text-md-left"
          >
            <b-col md="2">
              <b-skeleton-img height="113px" no-aspect></b-skeleton-img>
            </b-col>
            <div class="flex mb-32pt mb-md-0 mobilemb0">
              <h4 class="mb-0"><b-skeleton width="60%" height="1.5rem"></b-skeleton></h4>

              <b-row>
                <b-col md="2">
                  <b-skeleton type="button" width="100%"></b-skeleton>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>

        <div class="container page__container page-section student-card mt-0 pt-0 mb-32pt">
          <div class="card" v-for="i in [1, 2]" :key="i">
            <div class="card-body">
              <div class="d-flex mb-1">
                <div class="flex">
                  <div class="mb-1">
                    <h5 class="card-title fw600"><b-skeleton width="50%"></b-skeleton></h5>
                  </div>

                  <h6>
                    <b-skeleton width="70%"></b-skeleton>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="60%"></b-skeleton>
                  </h6>

                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-skeleton-wrapper>

    <div v-else>
      <div class="page-section pb10 student-page">
        <div class="container page__container d-flex align-items-center">
          <p class="backResult text-50">
            <a @click.prevent="goBack" class="clickable-item" style="text-decoration: underline">
              <span class="material-icons icon-18pt text-80">navigate_before</span>
            </a>
          </p>
        </div>

        <div
          class="container page__container d-flex flex-column flex-md-row align-items-center text-center text-md-left"
        >
          <!-- Old avatar image style (use if needed) -->
          <img
            :src="
              getLoggedInUser.role_type === USER_ROLE_TYPES.STUDENT
                ? get(application, 'simulation.posted_by.logo_url')
                : get(student.user, 'avatar_url')
                ? student.user.avatar_url
                : DefaultAvatar
            "
            width="115"
            class="mr-md-32pt mb-32pt mb-md-0 rounded mobilemb10"
          />

          <div class="flex mb-32pt mb-md-0 mobilemb0">
            <h4 style="margin-bottom: 0rem">
              <span v-if="getLoggedInUser.role_type !== USER_ROLE_TYPES.STUDENT">
                {{ student.user.first_name }} {{ student.user.last_name }}
              </span>
              <span v-else>
                {{ get(application, 'simulation.posted_by.name') }}
              </span>
            </h4>
            <div>Applied on: {{ formatDateTime(application.created_at) }}</div>

            <span
              v-if="
                getLoggedInUser.role_type !== USER_ROLE_TYPES.STUDENT &&
                  getLoggedInUser.role_type !== USER_ROLE_TYPES.SUPER_ADMIN
              "
              v-b-popover.hover.top
              :title="student.show_profile ? '' : `This student hasn't completed its profile yet.`"
            >
              <b-btn
                href="#"
                :disabled="!student.show_profile"
                variant="secondary"
                class="btn-normal mt-4 ml-2"
                @click.prevent="openStdProfile(student.id)"
                >Show Profile</b-btn
              >
            </span>
          </div>
        </div>
      </div>

      <div class="container page__container student-card" v-if="application.recording_url">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center mb-1">
              <h5 class="card-title fw600">Simulation</h5>
            </div>
            <audio controls>
              <source
                :src="application.recording_url"
                :type="`audio/${application.recording_url.split('.').slice(-1)}`"
              />
            </audio>
            <div class="mt-2" v-if="get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN">
              <h5 class="card-title fw600">Feedback</h5>
              <div class="container chat_container" style="font-style: italic;">
                <div v-if="application.feedback">{{ application.feedback }}</div>
                <div v-else class="no-data">No Feedback Submitted Yet!</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container page__container page-section student-card mt-0 pt-0 mb-32pt">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center mb-1">
              <h5 class="card-title fw600">Transcript</h5>
            </div>
            <p v-if="!application.transcript_object.length">
              <i>
                No Transcript Yet.
              </i>
            </p>
            <div v-else class="container chat_container">
              <div
                v-for="(chat, index) in application.transcript_object"
                :key="index"
                :class="chat.role === 'agent' ? 'chat-wrapper ai' : 'chat-wrapper'"
              >
                <div class="d-flex">
                  <div class="profile">
                    <img
                      :style="
                        `background-color: ${
                          chat.role === 'agent'
                            ? get(getLoggedInUser.linked_entity, 'primary_color')
                            : get(getLoggedInUser.linked_entity, 'secondary_color')
                        }`
                      "
                      :src="
                        chat.role === 'agent'
                          ? getImage(application.simulation.voice_id)
                          : get(student.user, 'avatar_url')
                          ? student.user.avatar_url
                          : DefaultAvatar
                      "
                      alt="agent"
                    />
                  </div>

                  <div class="col-10 ml-2">
                    <span v-html="formatText(chat.content)"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { get, trim } from 'lodash';

import Page from '@/components/Page.vue';
import { formatDateTime } from '@/common/utils';
import DefaultAvatar from '@/assets/images/people/user_avatar.png';
import { USER_ROLES, USER_ROLE_TYPES, SIMULATION_VOICES_OPTIONS } from '@/common/constants';

export default {
  components: {},
  extends: Page,
  name: 'SimulationProfile',

  data() {
    return {
      title: 'Student Simulation',
      student: {},
      isLoading: true,
      DefaultAvatar,
      USER_ROLES,
      USER_ROLE_TYPES,
      SIMULATION_VOICES_OPTIONS
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser'])
  },

  methods: {
    ...mapActions('simulations', ['getSimulationSubmission']),
    formatDateTime,
    get,
    goBack() {
      this.$router.go(-1);
    },
    formatText(string) {
      const result = trim(string, '\n');
      return result.replace(/\n/g, '<br />');
    },
    getImage(voice) {
      return require(`@/assets/images/avatars/${
        this.SIMULATION_VOICES_OPTIONS.find(item => item.value === voice).image
      }`);
    },
    openStdProfile(id) {
      if (this.getLoggedInUser.role_type === USER_ROLE_TYPES.ORGANIZATION) {
        this.$router.push({
          name: 'org-student-details',
          params: { id, to: 'app-profile', applicant_id: this.$route.params.id }
        });
      } else {
        this.$router.push({
          name: 'employer-unlocked-candidate-profile',
          params: { id, to: 'app-profile', applicant_id: this.$route.params.id }
        });
      }
    },
    async fetchStudent() {
      this.isLoading = true;

      let resp = (await this.getSimulationSubmission(this.$route.params.id)).data;

      const student = resp.student;

      this.student = student;

      this.application = resp;

      this.isLoading = false;
    }
  },

  async mounted() {
    if (this.$route.params.id) {
      await this.fetchStudent();
    }
  }
};
</script>
